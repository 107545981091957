<template>
  <div class="card p-shadow-6">
    <h1>Lista de Fichas de Colaboradores</h1>
    <div style="height: 65vh">
      <DataTable
        :value="usersList"
        responsiveLayout="scroll"
        ref="dt"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        sortField="name"
        :sortOrder="1"
        :scrollable="true"
        scrollHeight="flex"
        :filters.sync="filters"
      >
        <template #header>
          <div style="text-align: left">
            <MultiSelect name:="columns" inputId:="columns" :filter="true"
            v-model="selectedColumns" :options="columns" :dataKey="'field'"
            optionLabel="header" placeholder="Colunas" style="width: 20em" />
          </div>
        </template>
        <Column
          field="username"
          header="Colaborador"
          sortable
          filterField="username"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Ficha Colaborador'"
              @click="viewUser(slotProps.data.id)"
              >{{ slotProps.data.username }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          v-for="(col, index) of selectedColumns"
          :field="col.field"
          :header="col.header"
          :key="col.field + '_' + index"
          :dataType="
            col.filterType == 'date'
              ? 'date'
              : col.filterType == 'dropdown'
              ? 'boolean'
              : ''
          "
          sortable
        >
          <template #body="slotProps" v-if="col.filterType == 'date'">
            {{ formatDate(slotProps.data[col.field]) }}
          </template>
          <template
            v-if="col.filterType == 'input'"
            #filter="{ filterModel, filterCallback }"
          >
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template
            #filter="{ filterModel, filterCallback }"
            v-else-if="col.filterType == 'dropdown'"
          >
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="col.options"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
          <template
            #filter="{ filterModel, filterCallback }"
            v-else-if="col.filterType == 'date'"
          >
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
// import userservice from "../services/user.service";
import employeeservice from "../services/employee.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate } from "../helpers/helpers";

export default {
  name: "UsersInfo",
  data() {
    return {
      loading: true,
      usersList: [],
      columns: null,
      selectedColumns: null,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        username: { value: null, matchMode: FilterMatchMode.CONTAINS },
        department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        area: { value: null, matchMode: FilterMatchMode.CONTAINS },
        office: { value: null, matchMode: FilterMatchMode.CONTAINS },
        office365: { value: null, matchMode: FilterMatchMode.EQUALS },
        employeeStatus: { value: "Ativo", matchMode: FilterMatchMode.EQUALS },
        admissionDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        endContract: { value: null, matchMode: FilterMatchMode.DATE_IS },
        exitDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        number: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        function: { value: null, matchMode: FilterMatchMode.CONTAINS },
        function_description_url: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        updated: { value: null, matchMode: FilterMatchMode.DATE_IS },
        haveCv: { value: null, matchMode: FilterMatchMode.EQUALS },
        haveCc: { value: null, matchMode: FilterMatchMode.EQUALS },
        haveQc: { value: null, matchMode: FilterMatchMode.EQUALS },
        haveCarDeclaration: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  },
  created() {
    this.getColumns();
    this.getDefaultColumns();
  },
  async mounted() {
    await this.getEmployees();
  },
  methods: {
    getColumns() {
      this.columns = [
        {
          field: "employeeStatus",
          header: "Estado",
          filterType: "dropdown",
          options: ["Ativo", "Inativo"],
        },
        { field: "name", header: "Nome", filterType: "input" },
        { field: "department", header: "Departamento", filterType: "input" },
        { field: "area", header: "Área", filterType: "input" },
        { field: "office", header: "Escritório", filterType: "input" },
        {
          field: "office365",
          header: "Office365",
          filterType: "dropdown",
          options: ["Online", "Offline"],
        },
        {
          field: "admissionDate",
          header: "Data de Admissão",
          filterType: "date",
        },
        {
          field: "endContract",
          header: "Data de Fim de Contrato",
          filterType: "date",
        },
        { field: "exitDate", header: "Data de Saída", filterType: "date" },
        {
          field: "number",
          header: "Número de Colaborador",
          filterType: "input",
        },
        { field: "email", header: "Email Profissional", filterType: "input" },
        { field: "function", header: "Função", filterType: "input" },
        {
          field: "function_description_url",
          header: "Descrição da Função - Url",
          filterType: "input",
        },
        { field: "updated", header: "Última Actualiação", filterType: "date" },
        {
          field: "haveCv",
          header: "Tem CV",
          filterType: "dropdown",
          options: ["Sim", "Não"],
        },
        {
          field: "haveCc",
          header: "Tem CC",
          filterType: "dropdown",
          options: ["Sim", "Não"],
        },
        {
          field: "haveQc",
          header: "Tem Certificado Habilitações",
          filterType: "dropdown",
          options: ["Sim", "Não"],
        },
      ];
    },
    getDefaultColumns() {
      this.selectedColumns = [
        {
          field: "employeeStatus",
          header: "Estado",
          filterType: "dropdown",
          options: ["Ativo", "Inativo"],
        },
        { field: "department", header: "Departamento", filterType: "input" },
        { field: "area", header: "Área", filterType: "input" },
        { field: "office", header: "Escritório", filterType: "input" },
        {
          field: "office365",
          header: "Office365",
          filterType: "dropdown",
          options: ["Online", "Offline"],
        },
        {
          field: "admissionDate",
          header: "Data de Admissão",
          filterType: "date",
        },
        {
          field: "endContract",
          header: "Data de Fim de Contrato",
          filterType: "date",
        },
        { field: "exitDate", header: "Data de Saída", filterType: "date" },
      ];
    },
    formatDate(date) {
      if (date == "") {
        return date;
      }
      return getOnlyDate(date);
    },
    async getEmployees() {
      this.loading = true;
      return employeeservice.getAll().then((response) => {
        response.forEach((element) => {
          element["employeeStatus"] =
            element.employeeStatus == 1 ? "Ativo" : "Inativo";
          element["haveCv"] = element.documents.cv.url ? "Sim" : "Não";
          element["haveCc"] = element.documents.citizenCard.url ? "Sim" : "Não";
          element["haveQc"] = element.documents.qualificationsCertificate.url
            ? "Sim"
            : "Não";
          element["haveCarDeclaration"] = !element.hasCar
            ? "NA"
            : element.documents.carDeclaration.url
            ? "Sim"
            : "Não";
          element["exitDate"] =
            element.employeeExitDate != null && element.employeeExitDate != ""
              ? new Date(element.employeeExitDate)
              : "";
          element["endContract"] =
            element.employeeEndContract != null &&
            element.employeeEndContract != ""
              ? new Date(element.employeeEndContract)
              : "";
          element["admissionDate"] =
            element.employeeStartDate != null && element.employeeStartDate != ""
              ? new Date(element.employeeStartDate)
              : "";
          this.usersList.push(element);
        });
        return (this.loading = false);
      });
    },
    viewUser(userId) {
      let route = this.$router.resolve({
        path: `/users-info-view/${userId}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
td {
  width: 1px;
  white-space: nowrap;
}
.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-column-filter-drop {
  width: 75px !important;
}
.bananas {
  width: 100px !important;
}
</style>
