var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card p-shadow-6"},[_c('h1',[_vm._v("Lista de Fichas de Colaboradores")]),_c('div',{staticStyle:{"height":"65vh"}},[_c('DataTable',{ref:"dt",staticClass:"p-datatable-sm",attrs:{"value":_vm.usersList,"responsiveLayout":"scroll","filterDisplay":"row","loading":_vm.loading,"sortField":"name","sortOrder":1,"scrollable":true,"scrollHeight":"flex","filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"text-align":"left"}},[_c('MultiSelect',{staticStyle:{"width":"20em"},attrs:{"name:":"columns","inputId:":"columns","filter":true,"options":_vm.columns,"dataKey":'field',"optionLabel":"header","placeholder":"Colunas"},model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1)]},proxy:true}])},[_c('Column',{attrs:{"field":"username","header":"Colaborador","sortable":"","filterField":"username"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Ver Ficha Colaborador'),expression:"'Ver Ficha Colaborador'"}],staticClass:"clickable-item",on:{"click":function($event){return _vm.viewUser(slotProps.data.id)}}},[_vm._v(_vm._s(slotProps.data.username))])]}},{key:"filter",fn:function({ filterModel, filterCallback }){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_vm._l((_vm.selectedColumns),function(col,index){return _c('Column',{key:col.field + '_' + index,attrs:{"field":col.field,"header":col.header,"dataType":col.filterType == 'date'
            ? 'date'
            : col.filterType == 'dropdown'
            ? 'boolean'
            : '',"sortable":""},scopedSlots:_vm._u([(col.filterType == 'date')?{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.formatDate(slotProps.data[col.field]))+" ")]}}:null,(col.filterType == 'input')?{key:"filter",fn:function({ filterModel, filterCallback }){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}:(col.filterType == 'dropdown')?{key:"filter",fn:function({ filterModel, filterCallback }){return [_c('Dropdown',{staticClass:"p-column-filter",attrs:{"options":col.options,"showClear":true},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}:(col.filterType == 'date')?{key:"filter",fn:function({ filterModel, filterCallback }){return [_c('v-date-picker',{attrs:{"masks":{
              input: 'YYYY-MM-DD',
            }},on:{"input":function($event){return filterCallback()}},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('input',_vm._g({staticClass:"p-column-filter p-inputtext p-component",domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}:null],null,true)})})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }